import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import facebook from '../../../images/social-media/facebook.svg'
import twitter from '../../../images/social-media/twitter.svg'
import linkedin from '../../../images/social-media/linkedin.svg'
import youtube from '../../../images/social-media/youtube.svg'
import theme from '../../styling/theme'

const getImageSrc = (title: string) => {
  if (title) {
    let img = ''
    switch (title) {
      case 'linkedin':
        img = linkedin
        break
      case 'twitter':
        img = twitter
        break
      case 'youtube':
        img = youtube
        break
      case 'facebook':
        img = facebook
        break
      default:
        img = facebook
    }
    return img
  }
}

interface DataItem {
  title: string
  url: string
}
interface SocialMediaProps {
  data: Array<DataItem>
}
const SocialMedia: FunctionComponent<SocialMediaProps> = ({ data }) => {
  return (
    <Container>
      {data &&
        data.map((item, key) => {
          return (
            <SocialMediaLink href={item.url} key={key} target="_blank">
              <img src={getImageSrc(item.title)} alt="social media icon"/>
            </SocialMediaLink>
          )
        })}
    </Container>
  )
}

export default SocialMedia

const Container = styled.div`
  display: flex;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`

const SocialMediaLink = styled.a`
  background: ${theme.brand.blue};
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  & img {
    width: 15px;
    height: 15px;
  }

  &:not(:last-of-type) {
    margin-right: 6px;
  }

  &:hover {
    background: ${theme.text.second};
  }
`
