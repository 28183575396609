import React, { FunctionComponent } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import SubscribeBox from './SubscribeBox'
import styled from 'styled-components'
import MainGrid from '../../styling/MainGrid'
import Disclaimer from './Disclaimer'
import theme from '../../styling/theme'
import LogoBanner from './LogoBanner'
//import img from '../../../images/logo-banner/crown-commercial-supplier.png'
//import img2 from '../../../images/logo-banner/hm-treasury.png'
import img3 from '../../../images/logo-banner/NQA_ISO27001_BW.svg'
import img4 from '../../../images/logo-banner/NQA_ISO9001_BW.svg'
import img5 from '../../../images/logo-banner/global-cyber-alliance.png'
import { appendUrlPartnerTracking } from '../../../util/url'
import TrustBox from '../../Header/Trustpilot'

const logoBannerData = [
  {
    imgSrc: img3,
    alt: 'ISO27001',
  },
  {
    imgSrc: img4,
    alt: 'ISO9001',
  },
  {
    imgSrc: img5,
    alt: 'Global Cyber Alliance',
  },
]

interface ILink {
  title: string
  url: string
  tag?: boolean
  tagTitle?: string
}

interface Column {
  lebel: string
  url?: string
  links: Array<ILink>
}

interface FooterProps {
  isVisible?: boolean
  data: Array<Column>
}

const Footer: FunctionComponent<FooterProps> = ({ isVisible, data }) => {
  const CareerTag = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Home" }) {
        home {
          careerFooterTag
          careerFooterTagLabel
        }
      }
    }
  `)
  return (
    <MainGrid>
      <LogoBanner data={logoBannerData} />
      <Container isVisible={isVisible}>
        <SubscribeBox
          heading="Keep up to date with Bob's Business"
          subheading="Sign-up to our newsletter for regular updates on all things Bob's."
        />
        <LinksContainer>
          <LinksSection>
            <SectionHeading className="legal-text">
              Cybersecurity Training
            </SectionHeading>

            {data &&
              data.slice(0, 4).map((item, key) => {
                return (
                  <Section key={key} margin={key === 0}>
                    <StyledLink
                      to={appendUrlPartnerTracking(item.url)}
                      key={key}
                    >
                      {item.label}
                    </StyledLink>
                  </Section>
                )
              })}
          </LinksSection>
          <LinksSection>
            <SectionHeading className="legal-text">
              Bob's Business
            </SectionHeading>
            {data &&
              data.slice(4, data.length).map((item, key) => {
                return (
                  <Section key={key} margin={key === 0}>
                    {item.label == 'Careers' ? (
                      <TagLinkContainer key={key}>
                        <StyledLink to={appendUrlPartnerTracking(item.url)}>
                          {item.label}
                        </StyledLink>
                        {CareerTag.wpPage.home.careerFooterTag && (
                          <span>
                            {CareerTag.wpPage.home.careerFooterTagLabel}
                          </span>
                        )}
                      </TagLinkContainer>
                    ) : (
                      <StyledLink
                        to={appendUrlPartnerTracking(item.url)}
                        key={key}
                      >
                        {item.label}
                      </StyledLink>
                    )}
                  </Section>
                )
              })}
              <TrustBox />
          </LinksSection>
        </LinksContainer>
      </Container>
      <Disclaimer
        data={[
          {
            title: 'Terms',
            url: '/terms-and-conditions',
          },
          {
            title: 'Privacy',
            url: '/privacy-policy',
          },
        ]}
      />
    </MainGrid>
  )
}

export default Footer

interface ContainerProps {
  isVisible?: boolean
}
const Container = styled.footer<ContainerProps>`
  display: flex;

  flex-direction: column;
  padding-top: 6rem;
  border-top: 1px solid ${theme.grey.keyline};
  position: relative;
  background: white;
  z-index: 2;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`
const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const LinksSection = styled.section`
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    margin-top: 40px;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    & div:first-child {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1199px) {
    width: 52%;
    flex-direction: column;
    & div:first-child {
      padding-right: 6rem;
    }
  }
`
interface ISection {
  margin?: boolean
}

const Section = styled.div<ISection>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: ${(props) => (props.margin ? '10rem' : '0')};

  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    margin-bottom: ${(props) => (props.margin ? '20px' : '0px')};
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    padding-right: 0;
  }
`

const SectionHeadingLink = styled(Link)`
  font-size: 13px;
  font-weight: bold;
  line-height: 23px;
  color: ${theme.text.main} !important;
  margin-bottom: 2rem;
`

const SectionHeading = styled.p`
  font-weight: bold;
  color: ${theme.text.main};
  margin-bottom: 2rem;
`
interface LinkProps {
  nomarginbottom?: boolean
}
const StyledLink = styled(Link)<LinkProps>`
  &,
  &:link,
  &:visited {
    font-size: 16px;
    line-height: 26px;
    color: ${theme.text.second};
    font-weight: 300;
    margin-bottom: ${(props) => (props.nomarginbottom ? '0px' : '14px')};
    position: relative;
    opacity: 0.8;
    overflow: hidden;
  }
  &:hover,
  &:active {
    color: ${theme.brand.blue};
    text-decoration: none !important;
  }
  &::before {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0;
    background: ${theme.brand.blue};
    margin: 0 auto;
    background: linear-gradient(
      270deg,
      ${theme.brand.blue} 5%,
      rgba(0, 163, 199, 0.8) 10%,
      rgba(0, 163, 199, 0.1) 95%,
      ${theme.brand.blue}
    );
    transform-origin: left;
    transform: translateX(-100%);
    backface-visibility: hidden;
  }
  &:hover::before,
  &:active::before,
  &:focus::before {
    transition: transform 0.6s cubic-bezier(0.67, 0, 0.33, 1);
    transform: translateX(100%);
  }
`
const TagLinkContainer = styled.div`
  display: flex;
  align-items: flex-start;

  & span {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 9px;
    font-weight: 300;
    letter-spacing: -0.45px;
    line-height: 11px;
    background: ${theme.grey.inputHover};
    color: ${theme.text.white};
    border-radius: 3px;
    padding: 2px 5.5px;
    margin-left: 13px;
    margin-top: 7px;
  }
`
