import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import logo from '../../images/bobs-business-logo.png'
import arrow from '../../images/nav-arrow.svg'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import { appendUrlPartnerTracking } from '../../util/url'
import Button from './Button/Button'
import { LinkProps } from '@material-ui/core'

interface DropdownItem {
  title: string
  slug: string
}
interface DropdownItem2 {
  label: string
  url: string
}

interface Item {
  title: string
  slug: string
  superDropdown?: Array<DropdownItem>
}
interface Item2 {
  label: string
  url: string
  children?: Array<DropdownItem2>
}

interface NavigationProps {
  topNav: Array<Item2>
  stickyNavigation: Array<Item2>
  mobileNav: Array<Item>
}

const Navigation: FunctionComponent<NavigationProps> = ({
  topNav,
  stickyNavigation,
  mobileNav,
}) => {
  const [showDropDown, toggleDropDown] = useState(false)

  const [showMobileNav, setShowMobileNav] = useState(false)
  return (
    <Container>
      <TopNav>
        <MainGrid>
          <TopNavInner>
            <Link to={appendUrlPartnerTracking('/')}>
              <figure className="image">
                <img
                  src={logo}
                  alt="Bob's Business Logo"
                  style={{ height: '50px', width: 'auto' }}
                />
              </figure>
            </Link>
            <TopLinks>
              <StyledLink href="tel:+443300583009">
                    +44 (0) 330 058 3009
              </StyledLink>
              {topNav.map((edge, key) =>
                edge.children.length >= 1 ? (
                  <StyledLinkDropdown
                    to={appendUrlPartnerTracking(edge.url)}
                    key={key}
                  >
                    {edge.label}{' '}
                    <Arrow src={arrow} alt="Arrow" flip={showDropDown} />
                    <Dropdown>
                      {edge.children.map((item, key) => {
                        return (
                          <DropdownLink to={item.url} key={key}>
                            {item.label}
                          </DropdownLink>
                        )
                      })}
                    </Dropdown>
                  </StyledLinkDropdown>
                ) : (
                  <StyledLink to={appendUrlPartnerTracking(edge.url)} key={key}>
                    {edge.label}
                  </StyledLink>
                )
              )}

              {/* <a
                className="navbar-item"
                href="https://github.com/GatsbyCentral/gatsby-starter-wordpress"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={github} alt="Github" />
                </span>
              </a> */}
            </TopLinks>
            <TopLinksMobile>
              <Button small={true} href={appendUrlPartnerTracking('/pricing')}>
                Get started
              </Button>
              <BurgerMenu onClick={() => setShowMobileNav(!showMobileNav)}>
                <Line1 showMobileMenu={showMobileNav}></Line1>
                <Line2 showMobileMenu={showMobileNav}></Line2>
                <Line3 showMobileMenu={showMobileNav}></Line3>
              </BurgerMenu>
            </TopLinksMobile>
          </TopNavInner>
        </MainGrid>
      </TopNav>
      <MainGrid>
        <StickyNav>
          {stickyNavigation.map((edge, key) =>
            edge.children.length >= 1 ? (
              <StyledLinkDropdown
                to={appendUrlPartnerTracking(edge.url)}
                key={key}
              >
                {edge.label}{' '}
                <Arrow src={arrow} alt="Arrow" flip={showDropDown} />
                <Dropdown>
                  {edge.children.map((item, key) => {
                    return (
                      <DropdownLink to={item.url} key={key}>
                        {item.label}
                      </DropdownLink>
                    )
                  })}
                </Dropdown>
              </StyledLinkDropdown>
            ) : (
              <StyledLink to={appendUrlPartnerTracking(edge.url)} key={key}>
                {edge.label}
              </StyledLink>
            )
          )}
        </StickyNav>
        <MobileNav showMobileMenu={showMobileNav}>
          <StickyNavMobile>
            {mobileNav.map((edge, key) =>
              edge.children.length >= 1 ? (
                <MenuItem
                  key={key}
                  title={edge.label}
                  slug={edge.url}
                  superDropdown={edge.children}
                />
              ) : (
                <StyledLinkMobile
                  to={appendUrlPartnerTracking(edge.url)}
                  key={key}
                >
                  <MobileMenuItem>
                    {edge.label}
                    <RightArrow src={arrow} alt="Arrow" />
                  </MobileMenuItem>
                </StyledLinkMobile>
              )
            )}
            <StyledLinkMobile href="tel:+443300583009" >
                  <MobileMenuItem>
                    +44 (0) 330 058 3009
                    <RightArrow src={arrow} alt="Arrow" />
                  </MobileMenuItem>
            </StyledLinkMobile>
          </StickyNavMobile>
        </MobileNav>
        {/* </MainGrid> */}
      </MainGrid>
    </Container>
  )
}

export default Navigation

interface MenuItemProps {
  title: string
  slug: string
  superDropdown: Array<DropdownItem>
}
const MenuItem: FunctionComponent<MenuItemProps> = ({
  title,
  slug,
  superDropdown,
}) => {
  const [showSuperDropDown, setShowSuperDropDown] = useState(false)
  // console.log(superDropdown)
  return (
    <StyledLinkDropdownMobile
      onClick={() => setShowSuperDropDown(!showSuperDropDown)}
    >
      <MobileMenuItem flip={showSuperDropDown}>
        {title}
        <Arrow src={arrow} alt="Arrow" flip={showSuperDropDown} />
      </MobileMenuItem>
      {showSuperDropDown && (
        <SuperDropdownMobile>
          {superDropdown.map((item, key) => {
            return (
              <DropdownLinkMobile to={item.url} key={key}>
                <Dash />
                {item.label}
              </DropdownLinkMobile>
            )
          })}
        </SuperDropdownMobile>
      )}
    </StyledLinkDropdownMobile>
  )
}

const Container = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding: 0 20px;
  @media screen and (min-width: 1024px) {
    padding: 0;
  }
`
const TopNav = styled.div`
  border-bottom: 1px solid ${theme.grey.keyline};
  @media (max-width: 1024px) {
  }
`
const TopNavInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 0 1.3rem;
`

const TopLinks = styled.div`
  display: flex;
  align-items: center;

  & a:not(:last-of-type) {
    margin-right: 2.2rem;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const TopLinksMobile = styled.div`
  display: flex;
  align-items: center;

  & a:not(:last-of-type) {
    margin-right: 2.2rem;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`
interface LinkProp {
  firstLink?: boolean
  activeLink?: boolean
}
const StyledLink = styled(Link)<LinkProp>`
  &,
  &:link,
  &:visited {
    font-family: Montserrat;
    color: #1d253c;
    font-size: 13px;
    line-height: 16px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &::after {
      position: absolute;
      content: ' ';
      height: 3px;
      width: 100%;
      bottom: -1px;
      left: ${(props) => (props.firstLink ? '0' : '0%')};
      background: ${theme.brand.blue};
      transition: ease-out 0.3s;
      margin: 0 auto;
      transform: scaleX(0);
      transform: ${(props) => (props.activeLink ? 'scaleX(1)' : 'scaleX(0)')};
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scaleX(1);
    }
  }
  &:hover,
  &:focus {
    color: ${theme.brand.blue};
    text-decoration: none !important;
  }
`
const StyledHeader = styled.div<LinkProp>`
  /* border: 1px solid red; */
  margin-right: 10px;
  &,
  &:link,
  &:visited {
    font-family: Montserrat;
    color: #1d253c;
    font-size: 13px;
    line-height: 16px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &::after {
      position: absolute;
      content: ' ';
      height: 3px;
      width: 100%;
      bottom: -1px;
      left: ${(props) => (props.firstLink ? '0' : '0%')};
      background: ${theme.brand.blue};
      transition: ease-out 0.3s;
      margin: 0 auto;
      transform: scaleX(0);
      transform: ${(props) => (props.activeLink ? 'scaleX(1)' : 'scaleX(0)')};
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scaleX(1);
    }
  }
  &:hover,
  &:focus {
    color: ${theme.brand.blue};
    text-decoration: none !important;
  }
`

const StickyNav = styled.div`
  display: none;
  & a:not(:last-of-type) {
    margin-right: 4rem;
  }
  @media screen and (min-width: 1024px) {
    display: flex;

    /* border: 1px solid red; */
  }
`

const MobileNav = styled.div<BurgerProps>`
  display: ${(props) => (props.showMobileMenu ? 'flex' : 'none')};
  transition-duration: 1s;
  box-shadow: 1px;
  flex-direction: column;
  z-index: 200;
  //border: 1px solid blue;
`

const StyledLinkDropdown = styled.div<LinkProps>`
  margin-right: 30px;
  &,
  &:link,
  &:visited {
    font-family: Montserrat;
    color: #1d253c;
    font-size: 13px;
    line-height: 16px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &::after {
      position: absolute;
      content: ' ';
      height: 3px;
      width: 100%;
      bottom: -1px;
      left: ${(props) => (props.firstLink ? '0' : '0%')};
      background: ${theme.brand.blue};
      transition: ease-out 0.3s;
      margin: 0 auto;
      transform: scaleX(0);
      transform: ${(props) => (props.activeLink ? 'scaleX(1)' : 'scaleX(0)')};
    }
    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scaleX(1);
    }
  }
  &:hover,
  &:focus {
    color: ${theme.brand.blue};
    text-decoration: none !important;
  }
`

const DropdownLink = styled(Link)`
  &,
  &:link,
  &:active {
    width: 100%;
    color: ${theme.text.main} !important;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    padding: 20px;
  }
  &:hover,
  &:active {
    background: ${theme.brand.blue};
    color: white !important;
    text-decoration: none;
  }
`

const Dropdown = styled.div`
  flex-direction: column;
  position: absolute;
  top: 56px;
  left: 0;
  z-index: 100;
  display: none;
  width: 100%;
  background: white;
  width: 300px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  border: 1px solid #ececec;
  ${StyledLinkDropdown}:hover > & {
    display: flex;
  }
`
interface ArrowProps {
  flip?: boolean
}

const Arrow = styled.img<ArrowProps>`
  width: 12px;
  margin-left: 1rem;
  transition: all 0.3s ease;
  ${(props) => props.flip && 'transform: rotate(180deg);'}
  ${StyledLinkDropdown}:hover > & {
    transform: rotate(180deg);
  }
`
const RightArrow = styled.img<ArrowProps>`
  width: 12px;
  margin-left: 1rem;
  transition: all 0.3s ease;
  transform: rotate(-90deg);
`

const StyledLinkMobile = styled(Link)<LinkProp>`
  font-family: Montserrat;

  color: #1d253c;
  font-size: 2.4rem;
  line-height: 16px;
  min-height: 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  vertical-align: center;
  justify-content: flex-start; */
  position: relative;
  text-decoration: none;
  border-bottom: 1px solid ${theme.grey.keyline};
`

const DropdownLinkMobile = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: ${theme.text.main} !important;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 15px;
  padding: 20px;
`

const StickyNavMobile = styled.div`
  display: flex;
  position: absolute;
  padding: 0;
  top: 80px;
  left: 0;
  flex-direction: column;
  background-color: white;
  width: 100%;
  z-index: 20;
  overflow-y: scroll;
`
const MobileMenuItem = styled.div<ArrowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  vertical-align: center;
  height: 56px;
  padding: 0 20px;
  z-index: 10;
  background-color: white;
  text-decoration: none;
  font-size: 2.4rem;
  color: black;
  font-weight: ${(props) => (props.flip ? 'bold' : 'normal')};
`

const StyledLinkDropdownMobile = styled.div`
  border-bottom: 1px solid ${theme.grey.keyline};
`
const Dash = styled.div`
  background-color: ${theme.text.main};
  width: 15px;
  height: 1px;
  margin-right: 10px;
`
const SuperDropdownMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  width: 100%;
  background: ${theme.background.grey};
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  border: 1px solid #ececec;
`

const BurgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 30px;
  height: 21px;
  justify-content: space-between;
  margin: 0 10px;
`
interface BurgerProps {
  showMobileMenu: boolean
}
const Line1 = styled.div<BurgerProps>`
  height: 3px;
  width: 100%;
  position: relative;
  background-color: ${theme.brand.blue};

  transform: translateY(${(props) => (props.showMobileMenu ? '9px' : '')})
    ${(props) => (props.showMobileMenu ? 'rotate(45deg)' : '')};
  transition-duration: 0.5s;
`
const Line2 = styled.div<BurgerProps>`
  height: 3px;
  width: 100%;
  top: 10px;
  background-color: ${theme.brand.blue};
  transform: ${(props) => (props.showMobileMenu ? 'scaleX(0)' : 'scaleX(1)')};

  transition-duration: 0.5s;
`
const Line3 = styled.div<BurgerProps>`
  height: 3px;
  width: 100%;

  background-color: ${theme.brand.blue};

  transform: translateY(${(props) => (props.showMobileMenu ? '-9px' : '')})
    ${(props) => (props.showMobileMenu ? 'rotate(-45deg)' : '')};
  transition-duration: 0.5s;
`
