import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import SocialMedia from './SocialMedia'
import theme from '../../styling/theme'
import { appendUrlPartnerTracking } from '../../../util/url'

const socialMediaData = [
  {
    title: 'linkedin',
    url: 'https://www.linkedin.com/company/bobsbusinessuk/',
  },
  {
    title: 'youtube',
    url: 'https://www.youtube.com/channel/UCQaKyckI8G35cTcRyHben4g',
  },
  {
    title: 'twitter',
    url: 'https://twitter.com/BobsBusiness',
  },
  {
    title: 'facebook',
    url: 'https://www.facebook.com/BobsBusinessUK',
  },
]

interface ILink {
  title: string
  url: string
}
interface DisclaimerProps {
  data: Array<ILink>
}

const Disclaimer: FunctionComponent<DisclaimerProps> = ({ data }) => {
  return (
    <Container>
      <Col>
        <p className="legal-text">
          &#169; {new Date().getFullYear()} Bob's Business&reg;
        </p>
        <p className="legal-text">
          Company No. 06341794 | VAT. 917310152
        </p>
        <FooterLinks>
        {data &&
          data.map((item, key) => {
            return (
                <LinkWrapper key={key}>
                  <StyledLink to={appendUrlPartnerTracking(item.url)} key={key}>
                    {item.title}
                  </StyledLink>
                  {key !== data.length - 1 && <Line />}
                </LinkWrapper>
              
            )
          })}
          </FooterLinks>
      </Col>

      <SocialMedia data={socialMediaData} />
    </Container>
  )
}

export default Disclaimer

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 9rem 0 5rem;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin-right: 3rem;
    color: ${theme.text.main};
    font-weight: bold;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledLink = styled(Link)`
  &,
  &:link,
  &:visited {
    font-size: 13px;
    line-height: 23px;
    color: ${theme.text.main};
    position: relative;
    margin-right: 1.5rem;
    overflow: hidden;
  }
  &:hover,
  &:active {
    color: ${theme.brand.blue};
    text-decoration: none !important;
  }
  &::before {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0;
    background: ${theme.brand.blue};
    margin: 0 auto;
    background: linear-gradient(
      270deg,
      ${theme.brand.blue} 5%,
      rgba(0, 163, 199, 0.8) 10%,
      rgba(0, 163, 199, 0.1) 95%,
      ${theme.brand.blue}
    );
    transform-origin: left;
    transform: translateX(-100%);
    backface-visibility: hidden;
  }
  &:hover::before,
  &:active::before,
  &:focus::before {
    transition: transform 0.6s cubic-bezier(0.67, 0, 0.33, 1);
    transform: translateX(100%);
  }
`

const Line = styled.div`
  width: 1px;
  height: 10px;
  background: ${theme.grey.keyline};
  margin-right: 1.5rem;
`
