import React, { useState, FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'
import Input from '../Input'
import { validateEmail } from '../../../util/functions'
import arrow from '../../../images/link-arrow-white.svg'
import queryString from 'query-string'
import { useLocation } from '@reach/router';
interface SubscribeProps {
  heading: string
  subheading: string
}
const SubscribeBox: FunctionComponent<SubscribeProps> = ({
  heading,
  subheading,
}) => {
  const [email, setEmail] = useState('')
  const [emailError, toggleEmailError] = useState(false)

useEffect(()=>{
  if(email!==""){
  let idToScroll = ''
  if (!validateEmail(email)) {
    toggleEmailError(true)
    if (!idToScroll) {
      idToScroll = 'email'
    }
  } else {
    setEmail(email)
    toggleEmailError(false)
  }
  }
},[
  email
])
const location = useLocation();
const query = queryString.parse(location.search)
  // console.log(query)
  return (
    <Container>
      {!query.email ? (
      <form name={"newsletter-signup"} id={"news-letter-signup"}>
      {heading && <Heading>{heading}</Heading>}
      {subheading && <Subheading>{subheading}</Subheading>}
      <InputContainer hasError={emailError} isValidated={validateEmail(email)}>
        <Input
          type="text"
          id={"email"}
          name={"email"}
          value={email}
          onChange={(str: string) => {
             setEmail(str)
          }}
          isValidated={validateEmail(email)}
          hasError={emailError}
          onBlur={() => validateEmail(email)}
          id="email"
          noPaddingTop
          borderRadius="4px 0 0 4px"
          noPaddingTopMobile
          placeholder="Your email address"
        />
        <SubmitContiner>
        <ArrowImage src={arrow} alt="Arrow button" />
        <ArrowButton
          type="submit"
          value={""}
          hasError={emailError}
          isValidated={validateEmail(email)}
          disabled={emailError || email ===""}
        >
        
        </ArrowButton>
        </SubmitContiner>

  
   
      </InputContainer>
      </form>):(
        <SuccessMessage>
          Thanks for subscribing!
        </SuccessMessage>
      )}
    </Container>
  )
}

export default SubscribeBox

const SuccessMessage = styled.p`

  color: #00A3C7;

  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
`

const SubmitContiner = styled.div`
position: relative;
`

const ArrowImage = styled.img`
position: absolute;
height: 30px;
width: 40px;
z-index: 2;
top: 15px;
left: 10px;
pointer-events: none;
`

const Container = styled.section`
  width: 36%;
  margin-right: 13rem;
  & input {
    width: 100%;
    &:focus{
      outline: none !important;

    }
  }
  @media (max-width: 1199px) {
    width: 62%;
    margin-right: 11rem;
  }
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 500px;
    margin-right: 0;
  }
`
const Heading = styled.h4`
  font-size: 2rem;
  margin-bottom: 5px;
  font-weight: bold;
`

const Subheading = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 18px;
  color: ${theme.text.second};
  opacity: 0.8;
`

interface InputContainerProps {
  hasError?: boolean
  isValidated?: boolean
}
const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: flex-start;
  height: 100%;
  max-height: 60px;

  &:hover input {
    border-color: ${(props) =>
      props.isValidated
        ? theme.support.green
        : props.hasError
        ? theme.support.red
        : theme.grey.inputHover};
  }

  &:hover > a {
    background: ${(props) =>
      props.isValidated
        ? theme.support.green
        : props.hasError
        ? theme.support.red
        : theme.grey.inputHover};
  }
`
const ArrowButton = styled.input<InputContainerProps>`
  text-decoration: none;
  position: relative;
  content: "";
  max-width: 70px !important;
  min-width: 70px !important;
  
  left: -4px;
  border-radius: 0 4px 4px 0 !important;
  background-color: ${(props) =>
    props.isValidated
      ? theme.support.green
      : props.hasError
      ? theme.support.red
      : theme.brand.blue} !important;
  color: ${theme.text.white}!important;
  text-align: center;
  cursor: pointer;
  transition: background-color 200ms ease, color 200ms ease,
    background-color 200ms ease, transform 200ms ease,
    -webkit-transform 200ms ease !important;

  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:visited,
  &:link {
    text-decoration: none;
  }


`
