import React, { FunctionComponent, useState } from 'react'
import Helmet from 'react-helmet'
import Navigation from './shared/Navigation'
import Footer from './shared/Footer/Footer'
import './all.sass'
import './styling/layout.scss'
import Theme from './StyledComponents/Theme'
import GlobalStyles from './StyledComponents/GlobalStyles'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { appendUrlPartnerTracking } from '../util/url'
import { graphql, Link, useStaticQuery } from 'gatsby'
import PromotionBanner from './PromotionBanner'
import TrackVisibility from 'react-on-screen'
import PromotionBannerAnimation from './PromotionBannerAnimation'

const TemplateWrapper: FunctionComponent = ({ children }) => {
  const Menus = useStaticQuery(graphql`
    {
      allWpMenu {
        edges {
          node {
            name
            menuItems {
              nodes {
                label
                parentId
                id
                url
              }
            }
          }
        }
      }
      wpPage(title: { eq: "Footer Banner" }) {
        banner_info {
          body
          button1Text
          button2Text
          button2Url
          heading
          button1Url
        }
      }
    }
  `)
  let filteredMenus = []
  Menus.allWpMenu.edges.map((menu, i) => {
    menu.node.menuItems.nodes.map((menuitem, ii) => {
      if (menuitem.url.includes('/services/')) {
        let urlToReplace = menuitem.url.replace('/services/', '/')
        menuitem.url = urlToReplace
      }
      if (menuitem.url.includes('/standalone/')) {
        let urlToReplace = menuitem.url.replace('/standalone/', '/')
        menuitem.url = urlToReplace
      }
      if (menuitem.url.includes('/product/')) {
        let urlToReplace = menuitem.url.replace('/product/', '/products/')
        menuitem.url = urlToReplace
      }
    })
    filteredMenus.push(menu)
  })

  const flatListToHierarchical = (
    data = [],
    { idKey = 'id', parentKey = 'parentId', childrenKey = 'children' } = {}
  ) => {
    const tree = []
    const childrenOf = {}
    data.forEach((item) => {
      const newItem = { ...item }
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
      childrenOf[id] = childrenOf[id] || []
      newItem[childrenKey] = childrenOf[id]
      parentId
        ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
        : tree.push(newItem)
    })
    return tree
  }

  let topNav
  let mainNav
  let footerNav

  filteredMenus.map((menu, i) => {
    if (menu.node.name == 'Top Menu') {
      topNav = flatListToHierarchical(menu.node.menuItems.nodes)
    }
    if (menu.node.name == 'Main Menu') {
      mainNav = flatListToHierarchical(menu.node.menuItems.nodes)
    }
    if (menu.node.name == 'Footer Links') {
      footerNav = flatListToHierarchical(menu.node.menuItems.nodes)
    }
  })

  // if(filteredMenus[0].node.name =="Top Menu"){
  //   topNav = flatListToHierarchical(filteredMenus[0].node.menuItems.nodes);
  //    mainNav = flatListToHierarchical(filteredMenus[1].node.menuItems.nodes);
  // }
  // else{
  //    topNav = flatListToHierarchical(filteredMenus[1].node.menuItems.nodes);
  //    mainNav = flatListToHierarchical(filteredMenus[0].node.menuItems.nodes);

  // }

  const mobileNav = mainNav.concat(topNav)

  const dataPromotionBannerAnimation = {
    heading: Menus.wpPage.banner_info.heading,
    body: Menus.wpPage.banner_info.body,
    btnUrl1: Menus.wpPage.banner_info.button1Url,
    btnTitle1: Menus.wpPage.banner_info.button1Text,
    btnUrl2: Menus.wpPage.banner_info.button2Url,
    btnTitle2: Menus.wpPage.banner_info.button2Text,
  }

  function eraseCookie(name: string) {
    document.cookie = name + '=; Max-Age=0'
  }

  const text = 'This website uses cookies.'
  //console.log(Cookies.get() )
  return (
    <div>
      <Theme>
        <Helmet title="Bob's Business"></Helmet>
        <Navigation
          topNav={topNav}
          mobileNav={mobileNav}
          //stickyNavigation={stickyNavigation}
          stickyNavigation={mainNav}
        />
        <div>
          <GlobalStyles />
        </div>

        {children}
        <TrackVisibility partialVisibility>
          <PromotionBannerAnimation data={dataPromotionBannerAnimation} />
        </TrackVisibility>
        <Footer data={footerNav} />
      </Theme>
    </div>
  )
}

export default TemplateWrapper
