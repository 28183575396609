import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface Img {
  imgSrc: string
  alt: string
}
interface LogoBannerProps {
  data: Array<Img>
}

const LogoBanner: FunctionComponent<LogoBannerProps> = ({ data }) => {
  return (
    <Container>
      <Grid>
        {data &&
          data.map((item, key) => {
            return (
              <Mask key={key} order={key}>
                <Img src={item.imgSrc} alt={item.alt} />
              </Mask>
            )
          })}
      </Grid>
    </Container>
  )
}

export default LogoBanner

const Container = styled.div`
  border-top: 1px solid ${theme.grey.keyline};
  padding: 0;

  margin-top: 26px auto 0 ;
  
  @media screen and (min-width: 768px) {
    padding: 7.7rem 0 5.5rem;
    border-top: none;
    margin-top: 0;
  }
`

const Grid = styled.div`
  display: grid;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1px;
  background: ${theme.grey.keyline};
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 4rem;
    grid-template-rows: 40px;
    background: white;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 9rem;
    grid-template-rows: 40px;
    background: white;
  }
`
interface MaskProps {
  order: number
}
const Mask = styled.div<MaskProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  padding: 30px 30px;

  @media (max-width: 1023px) {
    display: ${(props) =>
      props.order === 4 || props.order === 5 ? 'none' : 'flex'};
  }
  @media (min-width: 1024px) {
    padding: 0;
    height: 40px;
  }
`

const Img = styled.img`
  height: auto;
  max-height: 100px;
  @media (min-width: 1024px) {
    /* max-height: 160px; */
    /* height: 40px; */
    /* background: red; */
    /* height: 100%; */
    /* max-height: auto; */
  }
`
